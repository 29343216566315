import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { removeContact } from '../../store/ducks/contacts';
import ContactsTable from '../../components/Tables/ContactTable/ContactsTable';
import { ExportCsv, ExportPdf } from '@material-table/exporters';

export default function Contacts() {
    const dispatch = useDispatch();
    const contacs = useSelector((state) => state.contacts.contacts);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={12}>
                        <Paper
                            sx={{
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <ContactsTable
                                icons={{
                                    Delete: (data) => !data.disabled && <DeleteOutline color="secondary" />,
                                }}
                                title={<h2>Kontaktok</h2>}
                                contacts={contacs}
                                editable={{
                                    onRowDelete: (rowData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                dispatch(removeContact(rowData._id));
                                                resolve();
                                            }, 0);
                                        }),
                                }}
                                tableOptions={{
                                    exportMenu: [
                                        {
                                            label: 'Export PDF (összes)',
                                            exportFunc: (cols, _datas) => {
                                                const allData = contacs;
                                                return ExportPdf(cols, allData, 'nectice-contacts-all');
                                            },
                                        },
                                        {
                                            label: 'Export PDF (látható)',
                                            exportFunc: (cols, datas) =>
                                                ExportPdf(cols, datas, 'nectice-contacts-filtered'),
                                        },
                                        {
                                            label: 'Export CSV (összes)',
                                            exportFunc: (cols, _datas) => {
                                                const allData = contacs;
                                                return ExportCsv(cols, allData, 'nective-contacts-all');
                                            },
                                        },
                                        {
                                            label: 'Export CSV (látható)',
                                            exportFunc: (cols, datas) =>
                                                ExportCsv(cols, datas, 'nective-contacts-filtered'),
                                        },
                                    ],
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
